jQuery( document ).ready(function($) {



  $('.menu-trigger').click(function(){

    $(this).toggleClass('active')

    $('.menu, #header .split .split').toggleClass('active')

  })

  $('.searchtoggle').on('click', function(e){
    e.preventDefault();
    $('#search-bar').toggleClass('open');
  })

});